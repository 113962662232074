/**
 *
 * AdminFooter
 *
 */
import * as React from "react"
// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap"

interface Props {}

export function AdminFooter(props: Props) {
  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted">
            © {new Date().getFullYear()}{" "}
            <a className="ml-1" href="/" rel="noopener noreferrer">
              Speedworks Courier
            </a>
          </div>
        </Col>
      </Row>
    </footer>
  )
}
