import { HomePage } from "../pages/HomePage/Loadable"
import { DeliveriesPage } from "app/pages/DeliveriesPage/Loadable"
import { UsersPage } from "app/pages/UsersPage/Loadable"
import { DeliveriesHubsPage } from "app/pages/DeliveriesHubsPage/Loadable"
import { ReportsPage } from "app/pages/ReportsPage/Loadable"
import { UploadPage } from "app/pages/UploadPage/Loadable"
import { UploadStatus } from "app/pages/UploadStatus/Loadable"
import { LoginPage } from "app/pages/LoginPage/Loadable"

const routes = [
  {
    path: "/",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <HomePage />,
    layout: "/admin",
    permissions: ["admin", "rider", "hub_admin"],
  },
  {
    path: "/deliveries",
    name: "Deliveries",
    icon: "ni ni-delivery-fast text-primary",
    component: <DeliveriesPage />,
    layout: "/admin",
    permissions: ["admin", "rider", "hub_admin"],
  },
  {
    path: "/upload",
    name: "Upload Orders",
    icon: "ni ni-cloud-upload-96 text-primary",
    component: <UploadPage />,
    layout: "/admin",
    permissions: ["admin"],
  },
  {
    path: "/upload-status",
    name: "Upload Status",
    icon: "ni ni-cloud-upload-96 text-primary",
    component: <UploadStatus />,
    layout: "/admin",
    permissions: ["admin"],
  },
  {
    path: "/reports",
    name: "Reports",
    icon: "ni ni-chart-bar-32 text-primary",
    component: <ReportsPage />,
    layout: "/admin",
    permissions: ["admin"],
  },
  {
    path: "/hubs",
    name: "Delivery Hubs",
    icon: "ni ni-bullet-list-67 text-primary",
    component: <DeliveriesHubsPage />,
    layout: "/admin",
    permissions: ["admin"],
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-circle-08 text-primary",
    component: <UsersPage />,
    layout: "/admin",
    permissions: ["admin"],
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <LoginPage />,
    layout: "/auth",
  },
]

export default routes
