import React from "react"
import { useLocation, Route, Routes, Navigate, Link } from "react-router-dom"
// reactstrap components
import { Container, Row, Col, Navbar, NavbarBrand } from "reactstrap"

import routes from "./routes"

const Auth = props => {
  const mainContent = React.useRef(null)
  const location = useLocation()

  React.useEffect(() => {
    document.body.classList.add("bg-default")
    return () => {
      document.body.classList.remove("bg-default")
    }
  }, [])
  React.useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    mainContent.current.scrollTop = 0
  }, [location])

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        )
      } else {
        return null
      }
    })
  }

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <Navbar
          className="navbar-top navbar-horizontal navbar-light"
          expand="md"
          color="light"
        >
          <Container className="px-4">
            <NavbarBrand to="/" tag={Link}>
              <img
                alt="Speedworks"
                src={require("../../assets/img/brand/SPEED-Logo.png")}
              />
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar-collapse-main">
              <span className="navbar-toggler-icon" />
            </button>
          </Container>
        </Navbar>
        <div className="header bg-gradient-danger py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">Welcome!</h1>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/auth/login" replace />} />
            </Routes>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Auth
