import React from "react"
import { useLocation, Route, Routes, Navigate } from "react-router-dom"
// reactstrap components
import { Container } from "reactstrap"
// core components
import { AdminNavbar } from "../components/AdminNavbar"
import { AdminFooter } from "../components/AdminFooter"
import { Sidebar } from "../components/Sidebar"
// route
import routes from "./routes.js"
import { Auth } from "../../utils/auth"

const Admin = props => {
  const mainContent = React.useRef(null)
  const location = useLocation()

  React.useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    mainContent.current.scrollTop = 0
  }, [location])

  const getRoutes = routes => {
    const userRole = Auth.role()
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" && prop.permissions.includes(userRole)) {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        )
      } else {
        return null
      }
    })
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin",
          imgSrc: require("../../assets/img/brand/SPEED-Logo.png"),
          imgAlt: "Speedworks",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          brandText={
            Auth.role() === "hub_admin" ? Auth.hubname() : "Speedworks"
          }
        />
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/admin/index" replace />} />
        </Routes>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  )
}

export default Admin
