/**
 *
 * Sidebar
 *
 */
import * as React from "react"
import { NavLink as NavLinkRRD, Link } from "react-router-dom"
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Col,
} from "reactstrap"

import { Auth } from "../../../utils/auth"

interface Props {
  location: any
  bgColor: String
  routes: any
  logo: any
}

export function Sidebar(props: Props) {
  const { bgColor, routes, logo } = props
  const [collapseOpen, setCollapseOpen] = React.useState<any>()

  const activeRoute = (routeName: any) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : ""
  }

  const toggleCollapse = () => {
    setCollapseOpen(data => !data)
  }

  const closeCollapse = () => {
    setCollapseOpen(false)
  }

  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes: any) => {
    const userRole = Auth.role()
    return routes.map((prop: any, key: any) => {
      if (prop.layout === "/auth" || !prop.permissions.includes(userRole))
        return
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      )
    })
  }

  let navbarBrandProps: any
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    }
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    }
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        {/* User */}
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header d-md-none">
            <Col className="collapse-close" xs="6">
              <button className="navbar-toggler" type="button">
                <span />
              </button>
            </Col>
          </div>
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}
