/**
 *
 * Asynchronously loads the component for DeliveriesPage
 *
 */

import { lazyLoad } from "utils/loadable"

export const DeliveriesPage = lazyLoad(
  () => import("./index"),
  module => module.DeliveriesPage,
)
