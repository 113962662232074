/**
 *
 * Asynchronously loads the component for DeliveriesHubsPage
 *
 */

import { lazyLoad } from "utils/loadable"

export const DeliveriesHubsPage = lazyLoad(
  () => import("./index"),
  module => module.DeliveriesHubsPage,
)
