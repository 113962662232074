/**
 *
 * Asynchronously loads the component for ReportsPage
 *
 */

import { lazyLoad } from "utils/loadable"

export const ReportsPage = lazyLoad(
  () => import("./index"),
  module => module.ReportsPage,
)
