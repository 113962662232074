/**
 *
 * Asynchronously loads the component for UploadStatus
 *
 */

import { lazyLoad } from "utils/loadable"

export const UploadStatus = lazyLoad(
  () => import("./index"),
  module => module.UploadStatus,
)
