/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from "react"
import { Helmet } from "react-helmet-async"
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom"

import { GlobalStyle } from "styles/global-styles"
import { NotFoundPage } from "./components/NotFoundPage/Loadable"
import { DocsPage } from "./pages/DocsPage/Loadable"
import { useTranslation } from "react-i18next"
import AdminLayout from "./layouts/Admin.js"
import AuthLayout from "./layouts/Auth.js"
// Utils
import { Auth } from "../utils/auth"

import { Toaster } from "react-hot-toast"

export function App() {
  const { i18n } = useTranslation()

  // private route means only logged user can access
  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const isLoggedIn = Auth.isLogged()
    const location = useLocation()
    if (!isLoggedIn) {
      return <Navigate to="/auth/login" state={{ from: location }} replace />
    } else {
      return children
    }
  }

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Speedworks CMS"
        defaultTitle=""
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="" />
      </Helmet>

      <Routes>
        <Route
          path="/admin/*"
          element={
            <RequireAuth>
              <AdminLayout />
            </RequireAuth>
          }
        />
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="/" element={<AuthLayout />} />
        <Route path="/docs" element={<DocsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <GlobalStyle />
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          }
        }}
      />
    </BrowserRouter>
  )
}
