import jwtDecode from "jwt-decode"
import { removeLocalStorage, getLocalStorage } from "./tokenstorage"

interface Token {
  exp: number
  role: string
  username: string
  id: number
  hub_id: number
  hub_name: string
}

export const Auth = {
  isLogged() {
    const storedToken = getLocalStorage() && getLocalStorage().token
    if (storedToken) {
      const token: Token = jwtDecode(storedToken)
      if (typeof token === "object" && token !== null) {
        if (token.exp < Date.now() / 1000) {
          removeLocalStorage()
          return false
        }
      }
      return true
    }
    return false
  },
  role() {
    const storedToken = getLocalStorage() && getLocalStorage().token
    if (storedToken) {
      const token: Token = jwtDecode(storedToken)
      return String(token.role)
    }
    return null
  },
  username() {
    const storedToken = getLocalStorage() && getLocalStorage().token
    if (storedToken) {
      const token: Token = jwtDecode(storedToken)
      return token.username
    }
    return null
  },
  userid() {
    const storedToken = getLocalStorage() && getLocalStorage().token
    if (storedToken) {
      const token: Token = jwtDecode(storedToken)
      return token.id
    }
    return null
  },
  hubid() {
    const storedToken = getLocalStorage() && getLocalStorage().token
    if (storedToken) {
      const token: Token = jwtDecode(storedToken)
      return token.hub_id
    }
    return null
  },
  hubname() {
    const storedToken = getLocalStorage() && getLocalStorage().token
    if (storedToken) {
      const token: Token = jwtDecode(storedToken)
      return token.hub_name
    }
    return null
  },
}
