/**
 *
 * Asynchronously loads the component for DocsPage
 *
 */

import { lazyLoad } from "utils/loadable"

export const DocsPage = lazyLoad(
  () => import("./index"),
  module => module.DocsPage,
)
